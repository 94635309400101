<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-4 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConfiguracionTablasProveedores",
  components: {
    PageHeader,
    GoBackBtn,
    Ayuda
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.views.sort(function(a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      title: "Configuración de tablas de proveedores",
      showExpand: false,
      showHelp: false,
      optionCode: enums.webSiteOptions.CONFIGURACION_TABLAS_PROVEEDORES,
      showIcon: true,
      routeToGo: "GestionProveedores",
      allowedActions: null,
      views: []
    };
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.webSiteOptions.PARAMETROS_GENERALES_PROVEEDORES:
            this.views.push({
              id: 1,
              title: x.title,
              order: 1,
              subtitle:
                "Configuración de los parámetros generales del proveedor.",
              path: "ParametrosGeneralesProveedores"
            });
            break;
          case enums.webSiteOptions.TIPOS_PROVEEDORES:
            this.views.push({
              id: 2,
              title: x.title,
              order: 2,
              subtitle: "Gestión de tipos de proveedor.",
              path: "TiposProveedor"
            });
            break;
          case enums.webSiteOptions.TIPOS_OPERACION_PROVEEDORES:
            this.views.push({
              id: 3,
              title: x.title,
              order: 3,
              subtitle: "Gestión de tipos de operación.",
              path: "TiposOperacionProveedores"
            });
            break;
        }
      });
    },
    whereToPush(item) {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
      this.$router.push({
        name: item.path
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
